<template>
  <div class="userInfoIndexPage">
    <h4 class="header">当前位置：个人中心 / 账号设置</h4>
    <a-card :bordered="false">
      <h4 class="card-head">账号设置</h4>
      <div class="card-content">
        <div class="item left">
          <h4>登录账号</h4>
          <div class="info one_line">{{userInfo.username}}</div>
        </div>
        <div class="item left">
          <h4>登录密码</h4>
          <div class="info one_line float">
            {{ password ? userInfo.password || '暂无' : "********" }}
            <div @click="this.password = !this.password">
              <eye-invisible-outlined v-show="!password" />
              <eye-outlined v-show="password" />
            </div>
          </div>
        </div>
        <div class="item right no-scale">
          <a-popconfirm
            title="请再次确认是否更改密码"
            ok-text="是"
            cancel-text="否"
            @confirm="goLogin"
          >
            <span class="change-password">更改密码</span>
          </a-popconfirm>
        </div>
        <div class="item iconcenter no-scale">
            <a-popconfirm
            title="请再次确认是否退出登录"
            ok-text="是"
            cancel-text="否"
            @confirm="outToLogin"
          >
            <a-button type="primary" danger>退出登录</a-button>
          </a-popconfirm>
        </div>
        
      </div>
    </a-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons-vue";
import setting from "@/config/setting";
export default {
  name: "UserInfoSetting",
  components: {
    EyeInvisibleOutlined,
    EyeOutlined,
  },
  data() {
    return {
      password: false,
    };
  },
  created() {},
  methods: {
    goLogin() {
      localStorage.removeItem(setting.tokenStoreName);
      sessionStorage.removeItem(setting.tokenStoreName);
      localStorage.removeItem(setting.userStoreName);
      this.$router.replace("/login?type=3");
    },
    outToLogin(){
         localStorage.removeItem(setting.tokenStoreName);
      sessionStorage.removeItem(setting.tokenStoreName);
      localStorage.removeItem(setting.userStoreName);
      this.$router.replace("/login");
    }
  },
  computed: {
    ...mapGetters(["classIndex", "userInfo"]),
  },
};
</script>